.App-content {
  padding-top: 100px;
  padding-bottom: 100px;
  max-width: 1200px;
  margin: auto;
}

.App-header {
  margin-bottom: 50px;
  padding-left: 15px;
  padding-right: 15px;
}

.draw-button {
  border: 1px solid #000;
  border-radius: 10px;
  padding: 10px 15px;
  margin-right: 15px;
  background: #fff;
}

.card-list {
  display: flex;
  padding: 15px;
}

.deck-card {
  flex: 1;
  aspect-ratio: 3/5;
  border: 1px solid #000;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  background: #fff;
}

.padoru {
  width: 250px;
  position: absolute;
  z-index: -1;
  bottom: 30px;
  right: 30px;
}
